import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";


import Tier2Layout from "../../components/content/Tier2Layout"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import ArticleSidebar from "../../components/content/ArticleSidebar"
import PageSectionHeadline from "../../components/content/PageSectionHeadline"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../../styles/tier2.css'
import '../../styles/events.css'
import events_hero from '../../images/events-hero-1.jpg';
import events_ideas_self from '../../images/events-ideas-self.jpg';
import events_ideas_golf from '../../images/events-ideas-golf.jpg';
import events_ideas_food from '../../images/events-ideas-food.jpg';
import events_ideas_photo from '../../images/events-ideas-photo.jpg';


const EventsIndex = () => {
    const pageData = {
                        seo:{
                            title:'Make Money with Travel Events',
                            description:"",
                            image:'/images/socialmedia/vacations-hero-fallbackposter.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Groups',location); 
    },[location]);

    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier2Layout showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

        <div id="Events_Hero">
            <div className="mast-seperator">
                <svg id="Tier2MastSeperatorSVG" data-name="Tier2MastSeperatorSVG" xmlns="http://www.w3.org/2000/svg" width="1320" height="96.8978" viewBox="0 0 1320 96.8978">
                <path class="background" d="M1320,51.7988H136.6818C1.0792,51.7988,0,96.8978,0,96.8978V-.3547H1320Z"/>
                <path class="middle-bar" d="M1320,38.7957H94.0934c-80.149,0-94.0934,20-94.0934,20V4.07s3.7773,20,92.9468,20H1320Z"/>
                <path class="upper-bar" d="M1.586,1.4134S17.79,20.75,95.2027,20.75C100.97,20.75,1320,21.0276,1320,21.0276V15.0245S114.4532,15.0121,95.8607,15.0121C20.0737,15.0121,1.586,1.4134,1.586,1.4134Z"/>
                <path class="lower-bar" d="M.0355,66.3013c9.1191-12.2588,35.4369-24.373,103.9433-24.2294,9.7635.02,1216.0212-.2762,1216.0212-.2762v6.0031s-1164.73,1.19-1183.3222,1.19C.2923,48.989,0,91.41,0,91.41Z"/>
                <polygon class="right-arrow" points="1315.675 48.499 1320 48.499 1320 10.898 1278.546 10.898 1315.675 48.499"/>
                </svg>
            </div>
            <div className="image">
                <img src={events_hero} width="100%" alt="Toasting Wine on a Travel Event" />
            </div>
            <div className="textcopy">
                <h1>Build Your Brand <span className="block">With <span className="hidden-xxl">Custom</span> Travel Events</span></h1>
                <p className="hidden-md"><span>More dedicated fans & customers.</span> <span>A free vacation for you.<sup><small>*</small></sup></span> <span>You could even make some money!<sup><small>*</small></sup></span></p>
                <p className="hidden-md hidden-lg hidden-xl">Outward Travel will help you every step of the way.</p>
            </div>
        </div>

        <Row>
            <Col md={8} className="Tier2MainText">
                <p>A customized travel event is a great way to build your brand and connect with your customers, fans, and followers. 
                    Social Media Influencers can organize a trip that excites their followers and grows sponsorships. Local businesses can invite their 
                    best customers to travel somewhere that enhances their brand and customer loyalty. Non-profits can host 
                    fundraisers in beautiful destinations. And when you 
                    organize a group travel event, the organizer can travel for free, or even make money!*
                </p>
                <p>Outward Travel combines our specialties in event planning, group travel, and technology to make it easy to design, promote, and execute 
                    a customized travel event. From group vacations to strategic sales events, we will design an experience
                    that makes you a hero to your customers. We'll provide the tools and advice for recruiting participants,
                    finding sponsors, collecting payments, and organizing schedules. We can even provide an on-site coordinator to 
                    keep all the details on track during the event.
                </p>
                <p style={{"margin-bottom":"25px"}}>Let's talk about your brand, your company, or your goals. We'll come up with some ideas and sample budgets, 
                    and then help you through every step of the process of launching and running your event. 
                </p>
                
                <ConsultationRequestTrigger color="green" onClick={()=>{startConsultationRequest('main_content')}} />

                <PageSectionHeadline color="blue" id="EventServicesHeading">What Outward Does For You</PageSectionHeadline>
        
                <p>Hosting an event can be daunting, but we have successfully built large and small events, so we will guide you throughout the process. 
                    Outward Travel's services include:
                </p>
                <ul>
                    <li>Designing all the travel components</li>
                    <li>Booking and managing travel suppliers (airlines, hotels, tours, etc)</li>
                    <li>Managing all participants' travel arrangements</li>
                    <li>Managing contracts and critical payment deadlines</li>
                    <li>Collaborating on event experience design - from swag bags to signage</li>
                    <li>Advising on getting sponsors to increase revenue or decrease participant price</li>
                    <li>Naming and branding assistance, including digital or printed collateral<sup><small>**</small></sup></li>
                    <li>Advising on a promotional &amp; recruitment strategy</li>
                    <li>A dedicated branded website for guests to sign up</li>
                    <li>A private, participant-only website for guests to connect, or manage additional sign-ups<sup><small>**</small></sup></li>
                    <li>Promoting your event through our channels</li>
                    <li>Providing on-site event management &amp; coordination<sup><small>**</small></sup></li>
                </ul>


                <p className="events_legalease">
                    * Getting a free vacation or additional revenue depends on how your event is structured.<br />
                    ** Additional fees may apply.
                </p>
            </Col>
            <Col md={4}>
                
                <ArticleSidebar id="EventsSidebar" headline={<><span className="hidden-md">Travel</span> Event Ideas</>} color="green">
                    <Row>
                        <Col xs={4} md={12} lg={4}><img src={events_ideas_food} width="100%" alt="Fine Food" /></Col>
                        <Col><b>Culinary Adventures</b> Food bloggers and local restaurants can expand your fans' and customers' palates.</Col>
                    </Row>
                    <Row>
                        <Col xs={4} md={12} lg={4}><img src={events_ideas_photo} width="100%" alt="Photographer" /></Col>
                        <Col><b>Photography Expeditions</b> Take your followers, students, or models on an trip for the best photos on earth.</Col>
                    </Row>
                    <Row>
                        <Col xs={4} md={12} lg={4}><img src={events_ideas_self} width="100%" alt="Meditation" /></Col>
                        <Col><b>Self-Discovery Retreats</b> Expand minds with guided meditation retreats, yoga on the beach, or simply a restorative vacation.</Col>
                    </Row>
                    <Row>
                        <Col xs={4} md={12} lg={4}><img src={events_ideas_golf} width="100%" alt="Golf" /></Col>
                        <Col><b>Corporate &amp; Non-Profit</b> Invite select customers and donors to a strategic event where they have fun and support your business.</Col>
                    </Row>
                </ArticleSidebar>
            </Col>
        </Row>


        </Tier2Layout>
    </main>
  )
}

 
export default EventsIndex;
